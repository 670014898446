import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import {
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  type PartialWithFieldValue,
  type Unsubscribe,
} from "firebase/firestore"

import { UserProfile } from "../entities/user_profile/user_profile"

export class UserProfileRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private documentReference(
    params: UserProfile.firestore.ParameterOfCollectionPath
  ) {
    return this.firestore.documentReference<UserProfile>(
      UserProfile.firestore.collectionPath(params)
    )
  }

  async fetch(
    params: UserProfile.firestore.ParameterOfCollectionPath
  ): Promise<UserProfile | undefined> {
    const snapshot = await getDoc(this.documentReference(params))

    return snapshot.data()
  }

  watch(
    params: UserProfile.firestore.ParameterOfCollectionPath,
    onData: (data: UserProfile | undefined) => void
  ): Unsubscribe {
    return onSnapshot(this.documentReference(params), (snapshot) => {
      if (!snapshot.exists()) {
        onData(undefined)
      }

      return onData(snapshot.data())
    })
  }

  async save({
    params,
    data,
  }: {
    params: UserProfile.firestore.ParameterOfCollectionPath
    data: PartialWithFieldValue<UserProfile>
  }) {
    await setDoc(
      this.documentReference(params),
      { ...data, updatedAt: serverTimestamp() },
      { merge: true }
    )
  }
}
