import { type NotEmptyArray } from "@/common/utils/type_helper"
import {
  AlternativeHolidayType,
  AttendanceApplicationType,
  HolidayType,
} from "@/domain/features/company/v2/entities/attendance_application/attendance_application"
import {
  HalfDayPaidLeavePeriod,
  PaidLeaveUnit,
} from "@/domain/features/company/v2/entities/paid_leave_unit/paid_leave_unit"
import { WorkerRole } from "@/domain/features/company/v2/entities/worker_readable/worker_readable"
import * as z from "zod"

import { ReviewResult } from "@/app/[companyId]/(admin)/admin-settings/_types/index"

export const sessionLoginSchema = z.object({
  idToken: z.string(),
})

export type SessionLogin = z.infer<typeof sessionLoginSchema>

export const nameSchema = z.object({
  lastName: z.string().min(1, {
    message: "姓を入力してください",
  }),
  firstName: z.string().min(1, {
    message: "名を入力してください",
  }),
})

export const workNoteSchema = z.object({
  note: z.string().max(100, {
    message: "勤務メモは100文字以内で入力してください",
  }),
})

export const editBaseSettingSchema = z.object({
  startTime: z
    .string()
    .regex(
      /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
      "出勤時間はH:mm形式で入力してください"
    ),
  endTime: z
    .string()
    .regex(
      /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
      "退勤時間はH:mm形式で入力してください"
    ),
  breakHours: z.number().min(0).max(8, "休憩時間は最大8時間です"),
  breakMinutes: z.number().min(0).max(59, "分は0から59の間で入力してください"),
})

export const AttendanceApplicationTypeSchema = z.enum(
  // 定数なので、値が保証されている
  AttendanceApplicationType.values as unknown as NotEmptyArray<AttendanceApplicationType>
)

export const attendanceListSchema = z.array(
  z.object({
    checkedInAt: z.date(),
    checkedOutAt: z.date(),
    checkInLocation: z
      .object({
        latitude: z.number(),
        longitude: z.number(),
      })
      .optional(),
    checkOutLocation: z
      .object({
        latitude: z.number(),
        longitude: z.number(),
      })
      .optional(),
    breakHours: z.number().min(0).max(8, "休憩時間は最大8時間です"),
    breakMinutes: z
      .number()
      .min(0)
      .max(59, "分は0から59の間で入力してください"),
    overtimeHours: z.number().min(0),
    overtimeMinutes: z
      .number()
      .min(0)
      .max(59, "分は0から59の間で入力してください"),
  })
)

export const editAttendanceHistorySchema = z.object({
  attendances: attendanceListSchema,
  attendanceMemo: z
    .string()
    .max(40, "備考は40文字以内で入力してください")
    .nullable()
    .optional(),
  applicationComment: z
    .string()
    .max(40, "備考は40文字以内で入力してください")
    .nullable()
    .optional(),
  applicationType: AttendanceApplicationTypeSchema.refine(
    (value) => value !== undefined,
    {
      message: "申請種別を選択してください",
    }
  ),
  holidayType: z.enum(HolidayType.values),
  alternativeHolidayType: z.enum(AlternativeHolidayType.values).nullable(),
  alternativeHolidayDate: z.date(),
  paidLeaveUnit: z.enum(PaidLeaveUnit.values),
  paidLeaveHalfDayPeriod: z.enum(HalfDayPaidLeavePeriod.values),
  paidLeaveHours: z.number(),
})

export const addMemberSchema = z.object({
  lastName: z.string().min(1, {
    message: "姓を入力してください",
  }),
  firstName: z.string().min(1, {
    message: "名を入力してください",
  }),
  role: z.enum(WorkerRole.values).default("none"),
  email: z.string().email({
    message: "正しいメールアドレスを入力してください",
  }),
})

export const editMemberRoleSchema = z.object({
  uid: z.string(),
  role: z.enum(WorkerRole.values).default("none"),
})

export const approvalSchema = z.object({
  id: z.string(),
  userId: z.string(),
  approval: z.enum([ReviewResult.approve, ReviewResult.reject]),
  reason: z
    .string()
    .max(30, "却下理由は30文字以内で入力してください")
    .optional(),
})

export const dateRangeSchema = z.object({
  dateRange: z
    .object({
      startDate: z.date().nullable(),
      endDate: z.date().nullable(),
    })
    .refine((value) => !value.startDate || !value.endDate, {
      message: "対象期間を選択してください",
    }),
})

export const editCompanyLogoURLFormSchema = z.object({
  url: z
    .string()
    .url({
      message: "正しいURLを入力してください",
    })
    .optional()
    .nullable(),
})
