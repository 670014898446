export interface NavItem {
  title: string
  relativePath?: string
  disabled?: boolean
  external?: boolean
  label?: string
  description?: string
}

export interface NavItemWithChildren extends NavItem {
  items: NavItemWithChildren[]
}

export interface NavItemWithOptionalChildren extends NavItem {
  items?: NavItemWithChildren[]
}

export interface FooterItem {
  title: string
  items: {
    title: string
    href: string
    external?: boolean
  }[]
}

export type MainNavItem = NavItemWithOptionalChildren

export type SidebarNavItem = NavItemWithChildren

export interface Option {
  label: string
  value: string
  icon?: React.ComponentType<{ className?: string }>
}

export interface StoredFile {
  id: string
  name: string
  url: string
}

export interface DataTableSearchableColumn<TData> {
  id: keyof TData
  title: string
}

export interface DataTableFilterableColumn<TData>
  extends DataTableSearchableColumn<TData> {
  options: Option[]
}

export interface Subcategory {
  title: string
  description?: string
  image?: string
  slug: string
}

export interface SubscriptionPlan {
  id: "basic" | "standard" | "pro"
  name: string
  description: string
  features: string[]
  stripePriceId: string
  price: number
}

export interface UserSubscriptionPlan extends SubscriptionPlan {
  stripeSubscriptionId?: string | null
  stripeCurrentPeriodEnd?: string | null
  stripeCustomerId?: string | null
  isSubscribed: boolean
  isCanceled: boolean
  isActive: boolean
}

export enum ApprovalStatus {
  unReviewed = "unReviewed",
  reviewed = "reviewed",
}

export enum ReviewResult {
  approve = "approve",
  reject = "reject",
}

// 残業時間の年間・月間
export enum OvertimeType {
  annual = "annual",
  monthly = "monthly",
}

export enum OvertimeSortType {
  overtimeDesc = "overtimeDesc",
  employeeNumber = "employeeNumber",
}
