import { type WorkerProfile } from "../worker_profile/worker_profile"

/**
 * 読み取り専用の従業員
 */
export interface WorkerReadable {
  userId: string
  workerNumber: string
  createdAt: Date
  updatedAt: Date
  role: WorkerRole
  profile?: WorkerProfile
  attendanceStatus?: WorkerAttendanceStatus
}

export type WorkerAttendanceStatus = "working" | "notWorking"
export type WorkerRole = (typeof WorkerRole.values)[number]
export namespace WorkerRole {
  export const values = ["admin", "none"] as const
}

export namespace WorkerReadable {
  export namespace firestore {
    export const collectionPath = ({ companyId }: { companyId: string }) =>
      `Company/v2/companies/${companyId}/workersReadable/`
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
  }

  export function sortByWorkerNumber(a: WorkerReadable, b: WorkerReadable) {
    return a.workerNumber.localeCompare(b.workerNumber)
  }
}
