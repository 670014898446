import Image from "next/image"
import { type UserAvatarIcon } from "@/domain/features/company/v2/entities/user_profile/user_profile"

type Props = {
  userAvatar?: UserAvatarIcon
  size: number
  className?: string
  priority?: boolean
}

const defaultIconPath = "/user_avatar_icons/person.svg"
export const getUserAvatarIconPath = (userAvatar?: UserAvatarIcon) => {
  // v1のアイコンが設定されているとき
  if (userAvatar?.image?.id) {
    return `/user_avatar_icons/${userAvatar.image.id}.svg`
  }

  return defaultIconPath
}

const UserAvatarIconView = ({ userAvatar, size, className }: Props) => {
  const src = getUserAvatarIconPath(userAvatar)

  return (
    <Image
      src={src}
      alt="user-icon"
      height={size}
      width={size}
      className={className}
      priority
    ></Image>
  )
}

export default UserAvatarIconView
