/**
 * ユーザー設定のプロフィール
 */
export interface UserProfile {
  firstName: string
  lastName: string
  firstNameRuby: string | undefined
  lastNameRuby: string | undefined
  avatarIcon?: UserAvatarIcon
  updatedAt: Date
  workerNumber: string
}

export namespace UserProfile {
  export namespace firestore {
    export const collectionPath = ({
      companyId,
      userId,
    }: {
      userId: string
      companyId: string
    }) => `Company/v2/users/${userId}/workingCompanies/${companyId}/profile/doc`
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
  }
}

/**
 * ユーザーのプロフィールアイコン
 */
export interface UserAvatarIcon {
  version: "v1"
  image: UserAvatarIconDelegate
}

export namespace UserAvatarIcon {
  export function v1(id: string): UserAvatarIcon {
    return {
      version: "v1",
      image: { id },
    }
  }
}

export type UserAvatarIconDelegate = UserAvatarIconDelegateV1

/**
 * ユーザーのプロフィールアイコンの中身(v1)
 */
export interface UserAvatarIconDelegateV1 {
  id: string
}

/**
 * v1のアイコンの情報
 */
export namespace UserAvatarIconV1 {
  export const ids = [
    "person",
    "polar-bear",
    "cherry-blossom",
    "elephant",
    "tiger",
    "alpaca",
    "dinosaur",
    "shrimp",
    "frog",
    "monkey",
    "octopus",
    "penguin",
    "palm-tree",
    "camel",
    "tuna",
    "koala",
    "cactus",
    "slug",
    "gorilla",
    "banana",
    "reindeer",
    "cat",
    "alligator",
    "mushroom",
  ]
}
